export default {
    data: {
        title: "",
        page: "create",
        btn: "",
    },
    action: () => {
        return new Promise(() => {
  
        })
    }
}
