<template>
  <button class="btn btn-primary w-100 mb-3" @click="onDeleteRecovery">
    <span class="spinner-border spinner-border-sm me-2" v-show="isShowLoadingBtn()"></span>
    Восстановить из удаления
  </button>
</template>

<script>
import {defineComponent, ref} from "vue"
import Api from "./services/Api"

export default defineComponent({
  name: "DeleteRecovery",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    let processing = ref(false)

    let postId = ref(props.data["post_id"])

    function onDeleteRecovery() {
      if (confirm("Вы действительно хотите восстановить пост?")) {
        startProcessing()

        Api.deleteRecovery(postId.value).then(() => {
          emit('onDeleteRecovery', true)
        }).catch(() => {
          emit('onDeleteRecovery', false)
        }).finally(() => {
          stopProcessing()
        })
      }
    }

    function isDisabledBtn() {
      return processing.value === true
    }

    function isShowLoadingBtn() {
      return isDisabledBtn()
    }

    function startProcessing() {
      processing.value = true
    }

    function stopProcessing() {
      processing.value = false
    }

    return {
      onDeleteRecovery,
      isDisabledBtn,
      isShowLoadingBtn,
    }
  },
})
</script>

<style scoped>

</style>
