import DTBackend from "@/services/DTBackend"

export default class Object {
    is_forever = false
    reason = ""
    date_until = null

    constructor({is_forever, reason, date_until}) {
        this.is_forever = is_forever
        this.reason = reason
        this.date_until = date_until
    }

    static buildData({is_forever, reason, date_until}) {
        return JSON.stringify({
            is_forever,
            reason,
            date_until,
        })
    }

    static getData(data) {
        return new Object(JSON.parse(data))
    }

    isForever() {
        return this.is_forever === true
    }

    getUntilDate() {
        let result, dt, dateUntil = this.date_until

        if (dateUntil === null) {
            return ""
        }

        dt = new DTBackend(dateUntil)
        dt.correctTZGet()

        result = dt.get("%D.%M.%y <span>%H:%I</span>")

        return result
    }

    hasReason() {
        return this.reason !== ""
    }

    hasUntilDate() {
        return this.date_until !== null
    }

    getReason() {
        return this.reason
    }
}
