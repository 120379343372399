<template>
  <div>
    <div class="input-icon mb-2">
      <Datepicker auto-apply @update:modelValue="onChangeDate" v-model="fields.date" locale="ru" format="dd.MM.yyyy" placeholder="Выбрать дату" :enable-time-picker="false" @cleared="onClear"/>
    </div>
    <template v-if="isTimeShow()">
      <div class="mb-2">
        <label class="form-label">Выбор времени</label>
        <div class="row g-2">
          <div class="col-6">
            <select class="form-select" v-model="v$.hours.$model" @change="onChangeHours" :class="{'is-invalid': v$.hours.$invalid && v$.hours.$dirty}">
              <Hours />
            </select>
            <div class="invalid-feedback" v-for="error of v$.hours.$silentErrors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
          <div class="col-6">
            <select class="form-select" v-model="v$.minutes.$model" @change="onChangeMinutes" :class="{'is-invalid': v$.minutes.$invalid && v$.minutes.$dirty}">
              <Minutes />
            </select>
            <div class="invalid-feedback" v-for="error of v$.minutes.$silentErrors" :key="error.$uid">
              {{ error.$message }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="mb-3">
      <span :class="{'is-invalid': v$.scheduled_published.$invalid && v$.scheduled_published.$dirty}"></span>
      <div class="invalid-feedback" v-for="error of v$.scheduled_published.$silentErrors" :key="error.$uid">
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, onMounted, ref, watch} from "vue"
import Datepicker from "@/plugins/datepicker"
import DT from "@/services/DT"
import Helper from "@/services/Helper"
import {useVuelidate} from "@vuelidate/core"
import {required, between, minLength} from "@/plugins/vuelidator"
import Hours from "./components/hours/Hours"
import Minutes from "./components/minutes/Minutes"

export default defineComponent({
  name: "ScheduledPublished",
  components: {
    Datepicker,
    Hours,
    Minutes,
  },
  props: {
    datetime: {
      type: String,
      default: null,
    },
    enable: {
      type: Boolean,
      default: false,
    },
    validations: {
      //type: Object,
    },
  },
  setup(props, {emit}) {
    let datetime = ref(props.datetime)

    let $externalResults = reactive({})

    const fields = reactive({
      scheduled_published: null,
      date: null,
      hours: null,
      minutes: null,
    })

    const rules = {
      scheduled_published: {
        minLength: minLength(0)
      },
      date: {},
      hours: {
        required,
        between: between(0, 23)
      },
      minutes: {
        required,
        between: between(0, 59)
      },
    }

    const v$ = useVuelidate(rules, fields, {$externalResults})

    onMounted(() => {
      if (datetime.value === null) {
        let date = Helper.getMoscowTime()

        fields.date = date
        fields.hours = date.getHours()
        fields.minutes = date.getMinutes()

        let datetime = new DT(fields.date).startTimeOfDay()
        datetime.setHour(fields.hours)
        datetime.setMinute(fields.minutes)
        datetime.setSec(0)

        emit("onChange", {
          "val": datetime.get("%Y-%M-%D %H:%I:%S"),
          "set_user": false,
        })

        return false
      }

      initDate()
    })

    watch(() => props.enable, (val) => {
      if (val === false) {
        resetAllFields()
        onChangeDatetime(false)
      }
    })

    watch(() => props.validations, (val) => {
      validate(() => {
        if (val?.scheduled_published !== undefined) {
          $externalResults.scheduled_published = val["scheduled_published"][0]
        }
      })
    })

    function initDate() {
      let d = new DT(datetime.value)

      fields.date = new Date(d.getFullYear(), d.getMonthNatural(), d.getDay(), 0, 0, 0)
      fields.hours = d.getHour()
      fields.minutes = d.getMinutes()
    }

    function onClear() {
      resetTime()
      onChangeDatetime(false)
    }

    function resetAllFields() {
      resetDate()
      resetTime()
    }

    function resetDate() {
      fields.date = null
    }

    function resetTime() {
      fields.hours = null
      fields.minutes = null
    }

    function isTimeShow() {
      return fields.date !== null
    }

    function onChangeDate(val) {
      fields.date = val
      onChangeDatetime()
    }

    function onChangeHours(e) {
      fields.hours = e.target.value
      onChangeDatetime()
    }

    function onChangeMinutes(e) {
      fields.minutes = e.target.value
      onChangeDatetime()
    }

    function onChangeDatetime(checkValidate = true) {
      if (checkValidate === true) {
        validate(change)
      } else {
        change()
      }
    }

    function validate(fn) {
      v$.value.$clearExternalResults()
      return v$.value.$validate().then(() => {
        fn()
      })
    }

    function change() {
      if (fields.date === null) {
        emit("onChange", {
          "val": null,
          "set_user": true,
        })
      } else if (Helper.isInt(fields.hours) && Helper.isInt(fields.minutes)) {
        let datetime = new DT(fields.date).startTimeOfDay()

        datetime.setHour(fields.hours)
        datetime.setMinute(fields.minutes)
        datetime.setSec(0)

        emit("onChange", {
          "val": datetime.get("%Y-%M-%D %H:%I:%S"),
          "set_user": true,
        })
      }
    }

    return {
      onClear,
      isTimeShow,
      onChangeDate,
      onChangeHours,
      onChangeMinutes,
      v$,
      fields,
    }
  }
})
</script>

<style scoped>

</style>
