import Object from "./Object"

function buildData(userId, userName) {
    return Object.buildData({
        user_id: userId,
        user_name: userName,
    })
}

export {buildData}
