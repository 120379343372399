<template>
  <div class="datagrid-item">
    <div class="datagrid-title">Тип скидки</div>
    <div class="datagrid-content mt-1"><b>{{ getVal() }}</b></div>
  </div>
</template>

<script>
import {defineComponent} from "vue"
import optionValues from "@/modules/promopushka/services/values"

export default defineComponent({
  name: "TypeCoupon",
  props: {
    data: {
      type: String,
      require: true,
    },
  },
  setup(props) {
    function getVal() {
      let typeOfCouponId = props.data?.type_of_coupon
      let typeOfStepsId = props.data?.type_of_steps

      if (typeOfCouponId === undefined) {
        throw new Error("Не указан type_of_coupon")
      }

      if (typeOfStepsId === undefined) {
        throw new Error("Не указан type_of_steps")
      }

      if (typeOfCouponId === null || typeOfStepsId === null) {
        return "Не выбран"
      }

      let items = detect(typeOfStepsId)

      if (items === null) {
        return "Не определен"
      }

      return Object.values(items).map(item => item.name).join(", ")
    }

    function detect(typeOfStepsId) {
      let typeOfSteps = optionValues.types.steps.find((item) => {
        return item.id === typeOfStepsId
      })
      
      let availableTypes = typeOfSteps?.coupon ?? []
      let items = optionValues.types.coupons.filter((type) => {
        return availableTypes.includes(type.id)
      })

      if (items.length === 0) {
        return null
      }
      
      let result = []

      Object.entries(items).forEach(([, val]) => {
        result.push({
          name: val.name
        })
      })

      return result
    }

    return {
      getVal,
    }
  }
})
</script>

<style scoped>

</style>
