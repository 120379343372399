<template>
  <div class="card-body" id="editor">
    <div class="divide-y">
      <div class="row">
        <span class="col-auto">
          <span class="form-label-value" v-html="getVal()"></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue"
import Helper from "@/services/Helper"
import "./css/style.css"

export default defineComponent({
  name: "Editor",
  props: {
    data: {
      type: String,
      require: true,
    },
  },
  setup(props) {
    function getVal() {
      let val = Helper.stripHTMLTags(props.data["description"])
      
      if (val === "") {
        return "Контент отсутствует..."
      }

      return props.data["description"]
    }

    return {
      getVal,
    }
  }
})
</script>

<style scoped>

</style>
