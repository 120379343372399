<template>
  <button class="btn btn-red w-100" data-bs-toggle="modal" data-bs-target="#post-remove">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-trash">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M4 7l16 0" />
      <path d="M10 11l0 6" />
      <path d="M14 11l0 6" />
      <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
      <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
    </svg>

    <template v-if="obj.status.isPreDeleted()">
      Повторить удаление
    </template>
    <template v-else>
      Удалить
    </template>
  </button>

  <div class="modal modal-blur fade" id="post-remove" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Удаление</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ref="closeModal"></button>
        </div>
        <div class="modal-body">
          <button class="btn btn-red w-100 mb-3" :class="{'disabled': isDisabledBtn()}" @click="onDelete">
            <span class="spinner-border spinner-border-sm me-2" v-show="isShowLoadingBtn()"></span>
            Удалить
          </button>
          <button class="btn btn-warning w-100" :class="{'disabled': isDisabledChainBtn()}" @click="onDeleteChain">
            <span class="spinner-border spinner-border-sm me-2" v-show="isShowLoadingChainBtn()"></span>
            Удалить цепочку постов
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue"
import Api from "./services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "Remove",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    let deleting = ref(false)
    let deletingChain = ref(false)
    let closeModal = ref(null)

    let postId = ref(props.data["post_id"])
    let postInChain = ref(props.data["post_in_chain"])
    let status = ref(props.data["status"])

    function onDelete() {
      if (confirm("Вы действительно хотите удалить пост?")) {
        startDeleting()

        Api.delete(postId.value).then(() => {
          emit('onPostRemove', true)
        }).then(() => {
          Notify.success("Поставлена задача на удаление")
          closeModalWindow()
        }).catch(() => {
          emit('onPostRemove', false)
        }).finally(() => {
          stopDeleting()
        })
      }
    }

    function onDeleteChain() {
      if (confirm("Вы действительно хотите удалить всю цепочку постов?")) {
        startDeletingChain()

        Api.deleteChain(postId.value).then(() => {
          emit('onRemove', true)
        }).then(() => {
          Notify.success("Цепочка удалена")
          closeModalWindow()
        }).catch(() => {
          emit('onRemove', false)
        }).finally(() => {
          stopDeletingChain()
        })
      }
    }

    function isDisabledBtn() {
      return deleting.value === true
    }

    function isShowLoadingBtn() {
      return isDisabledBtn()
    }

    function isDisabledChainBtn() {
      return deletingChain.value === true || (postInChain.value === 0)
    }

    function isShowLoadingChainBtn() {
      return deletingChain.value === true
    }

    function startDeleting() {
      deleting.value = true
    }

    function stopDeleting() {
      deleting.value = false
    }

    function startDeletingChain() {
      deletingChain.value = true
    }

    function stopDeletingChain() {
      deletingChain.value = false
    }

    function closeModalWindow() {
      closeModal.value.click()
    }

    let obj = {
      status: {
        isPreDeleted: () => {
          return status.value === "preDeleted"
        },
      },
    }

    return {
      onDelete,
      onDeleteChain,
      isDisabledBtn,
      isShowLoadingBtn,
      isDisabledChainBtn,
      isShowLoadingChainBtn,
      closeModal,
      obj,
    }
  },
})
</script>

<style scoped>

</style>