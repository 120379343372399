import ApiBase from "@/modules/users/services/ApiBase"

export default class Api {
    static bans(userId) {
        return ApiBase.bansToUser(userId).then((resp) => {
            return resp.data
        })
    }

    static unbindBan(userId, banId, sourceId) {
        return ApiBase.userUnbindBan(userId, banId, sourceId).then((resp) => {
            return resp.data
        })
    }

    static bindBan(userId, banId, sourceId) {
        return ApiBase.userBindBan(userId, banId, sourceId).then((resp) => {
            return resp.data
        })
    }
}
