import AuthPermission from "@/modules/auth/services/Permission"
import cfg from "@/services/Config"

export default new class Permission extends AuthPermission {
    isUpdate(channelId) {
        let permissions = this.getPermissions(channelId)

        if (permissions === false) {
            return false
        }

        return this.hasPermissions(permissions)
    }

    isCreate(channelId) {
        let permissions = this.getPermissions(channelId)

        if (permissions === false) {
            return false
        }

        return this.hasPermissions(permissions)
    }

    isDelete(channelId) {
        let permissions = this.getPermissions(channelId)

        if (permissions === false) {
            return false
        }

        return this.hasPermissions(permissions)
    }

    getPermissions(channelId) {
        let list = cfg._("post", "channel_permissions")
        
        if (list === undefined) {
            throw new Error("Отсутствует список разрешений к каналам")
        }

        return list[channelId] !== undefined ? list[channelId] : false;
    }
}
