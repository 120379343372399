export default {
    getBanId: (item) => {
        return item["ban_id"]
    },
    getBanCode: (item) => {
        return item["ban_code"]
    },
    getBanName: (item) => {
        return item["ban_name"]
    },
    getBanChecked: (item) => {
        return item["ban_checked"]
    },
    getSourceId: (item) => {
        return item["source_id"]
    },
    getIsForver: (item) => {
        return item["is_forever"]
    },
    getReasonDesc: (item) => {
        return item["reason_desc"]
    },
    getUntilAt: (item) => {
        return item["until_at"]
    },
}
