export default {
    data: {
        title: "Бан",
        page: "create",
    },
    action: () => {
        return new Promise(() => {
  
        })
    }
}
