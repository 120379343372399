<template>
  <div>
    <metainfo></metainfo>
    <template v-if="conditions.isNoPermission()">
      <NoPermission />
    </template>
    <template v-else-if="conditions.isReadyPage()">
      <div id="coupon-card">
        <template v-if="conditions.isReadyPage()">
          <div class="container-xl">
            <div class="page-header d-print-none">
              <div class="row g-2 align-items-center">
                <div class="col-sm">
                  <h2 class="page-title">
                    <Title :substring="getTitle()"/>
                  </h2>
                </div>
                <div class="col-auto" v-if="conditions.hasIdPost()">
                  <div class="me-1">
                    <ViewBtn :data="{
                      post_id: bootData.id,
                    }" />
                  </div>
                </div>
                <div class="col-auto" v-if="conditions.hasIdPost()">
                  <div class="me-1">
                    <PostInChain :post-id="chainObject.getId()" :type-id="chainObject.getTypeId()" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="page-body">
            <div class="container-xl">
              <template v-if="conditions.hasIdPost()">
                <CommentModeration :payload="{
                  categories: ['new'],
                  limit: 20,
                  title: 'Новые комментарии',
                  filter: {
                    post_id: bootData.id,
                    object_type_id: commentModerationObject.getTypeId(),
                  }
                }" />
              </template>

              <div class="row">
                <div class="col-md-8">
                  <div class="mb-3">
                    <TitleField :data="{
                      val: bootData.title,
                      validations: validations
                    }" @onChange="events.onChangeTitle" />
                  </div>
                  <div class="mb-3">
                    <EditorField :validations="validations" :description_source="bootData.description_source" @onChange="events.onChangeDescription" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3">
                    <ShopField :data="{
                      val: bootData.shop_id,
                      validations: validations,
                      shops: shops,
                    }" @onChange="events.onChangeShopId" />
                  </div>
                  <div class="mb-3">
                    <TypeStepsField :data="{
                      val: bootData.type_of_steps,
                      validations: validations
                    }" @onChange="events.onChangeTypeSteps" />
                  </div>
                  <div class="mb-3" v-if="conditions.typeOfStepIsWithCheck()">
                    <UrlCheckField :data="{
                      val: bootData.url_for_check,
                      validations: validations
                    }" @onChange="events.onChangeUrlForCheck" />
                  </div>
                  <div class="mb-3" v-if="conditions.typeOfStepIsWithCheck()">
                    <CodeCheckField :data="{
                      val: bootData.code_to_check,
                      validations: validations
                    }" @onChange="events.onChangeCodeToCheck" />
                  </div>
                  <div class="mb-3">
                    <TypeCouponField :data="{
                      val: bootData.type_of_coupon,
                      type_of_steps: bootData.type_of_steps,
                      validations: validations
                    }" @onChange="events.onChangeTypeCoupon" />
                  </div>
                  <div class="mb-3" v-if="conditions.typeOfCouponIsOneToMany()">
                    <CouponField :data="{
                      val: bootData.coupon,
                      validations: validations
                    }" @onChange="events.onChangeCoupon" />
                  </div>
                  <div class="mb-3">
                    <UrlActivateField :data="{
                      val: bootData.url_to_activate,
                      validations: validations
                    }" @onChange="events.onChangeUrlToActivate" />
                  </div>
                  <div class="mb-3">
                    <StatusField v-if="!conditions.isPublished()" :data="{
                      val: bootData.status,
                      onlyDisable: conditions.typeOfCouponIsOneToOneAndIsCreating(),
                      validations: validations
                    }" @onChange="events.onChangeStatus" />
                  </div>
                  <div class="mb-3">
                    <IsPartnerLinksField :validations="validations" :data="{
                      enable: bootData.partner_links,
                    }" @onChange="events.onChangeIsPartnerLinks" />
                  </div>
                  <div class="mb-3" v-if="!conditions.isDeleted()">
                    <AutoDeleteDate :validations="validations" :datetime="bootData.auto_delete_date" @onChange="events.onChangeAutoDeleteDate" />
                  </div>
                  <div class="mb-3" v-if="!conditions.isDeleted()">
                    <UploadDate :validations="validations" :datetime="bootData.upload_date" @onChange="events.onChangeUploadDate" />
                  </div>
                  <div class="mb-3">
                    <SendToTgField :validations="validations" :data="{
                      enable: bootData.send_to_tg,
                    }" @onChange="events.onChangeSendToTg" />
                  </div>

                  <div class="card mb-3">
                    <div class="card-body">
                      <button class="btn btn-green w-100" :class="{ 'disabled': conditions.isDisabledSaveBtn() }" @click="events.onSave">
                        <span class="spinner-border spinner-border-sm me-2" v-show="conditions.isShowLoadingSaveBtn()"></span>
                        Сохранить
                      </button>
                      <button v-if="conditions.isCreated() && !conditions.isDeleted()" class="btn btn-danger w-100 mt-3" :class="{ 'disabled': conditions.isDisabledDeleteBtn() }" @click="events.onDelete">
                        <span class="spinner-border spinner-border-sm me-2" v-show="conditions.isShowLoadingDeleteBtn()"></span>
                        Удалить
                      </button>
                      <div v-if="conditions.isCreated() && conditions.isDeleted()" class="mt-3">
                        <DeleteRecovery :data="{
                          post_id: bootData.id,
                        }" @onDeleteRecovery="events.onDeleteRecovery" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb-3" v-if="conditions.hasIdPost()">
                  <PostRefPosts :data="{
                    id: chainObject.getId(),
                    channel_id: chainObject.getChannelId(),
                    status: chainObject.getStatus(),
                    del: chainObject.getDel(),
                  }" :type-id="chainObject.getTypeId()" />
                </div>

                <div class="mb-3" v-if="conditions.hasIdPost()">
                  <CommentModeration :payload="{
                    post_id: bootData.id,
                    categories: ['approved', 'rejected', 'removed'],
                    object_type_id: commentModerationObject.getTypeId(),
                    limit: 20,
                    force_show: true,
                    title: 'Промодерированные комментарии',
                    filter: {
                      post_id: bootData.id,
                      object_type_id: commentModerationObject.getTypeId(),
                    }
                  }" />
                </div>

              </div>
              <div v-if="conditions.needCouponList()">
                <CouponList :coupon="bootData.id" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="page-body">
            <div class="container-xl">
              <div class="row">
                <div class="mb-3">
                  <div class="loader-posts">
                    <div class="spinner-border"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>

  </div>
</template>

<script>
import './style.css'
import {defineComponent, onMounted, ref, reactive, watch} from "vue"
import {useMeta} from "vue-meta"
import {useRoute} from "vue-router/dist/vue-router"
import EditorField from "./components/editor/Editor"
import TitleField from "./components/fields/Title"
import TypeStepsField from "./components/fields/TypeSteps"
import TypeCouponField from "./components/fields/TypeCoupon"
import StatusField from "./components/fields/Status"
import ShopField from "./components/fields/Shop"
import UrlCheckField from "./components/fields/UrlCheck"
import CodeCheckField from "./components/fields/CodeCheck"
import UrlActivateField from "./components/fields/UrlActivate"
import CouponField from "./components/fields/Coupon"
import IsPartnerLinksField from "./components/fields/IsPartnerLinks"
import AutoDeleteDate from "./components/fields/AutoDeleteDate"
import UploadDate from "./components/fields/UploadDate"
import SendToTgField from "./components/fields/SendToTg"
import Title from "@/modules/promopushka/components/title/Title"
import Api from "./services/Api"
import CouponList from './components/coupon_list/CouponList'
import PostRefPosts from "@/components/object_chains/PostRefPosts"
import cfg from "@/services/Config"
import PostStoreCommon from "@/modules/post/pages/posts/services/PostStoreCommon"
import PostInChain from "@/components/post_in_chain/PostInChain"
import CommentModeration from "@/components/comment_moderation/CommentModeration"
import Notify from "@/services/Notify"
import Permission from "@/modules/users/services/Permission"
import DeleteRecovery from "../../components/delete_recovery/DeleteRecovery"
import ViewBtn from "../../components/view_btn/ViewBtn"
import {useRouter} from "vue-router"
import NoPermission from "@/modules/auth/pages/no_permission/Index"
import {
  channelPromopushkaCode,
} from "@/modules/auth/services/permissions"

export default defineComponent({
  name: "Index",
  components: {
    NoPermission,
    DeleteRecovery,
    CommentModeration,
    Title,
    EditorField,
    TypeStepsField,
    TypeCouponField,
    TitleField,
    StatusField,
    ShopField,
    UrlCheckField,
    CodeCheckField,
    UrlActivateField,
    CouponField,
    IsPartnerLinksField,
    AutoDeleteDate,
    UploadDate,
    SendToTgField,
    CouponList,
    PostRefPosts,
    PostInChain,
    ViewBtn,
  },
  setup() {
    const route = useRoute()
    let titlePage = ref(null)
    let readyPage = ref(false)
    let couponId = ref(null)
    let validations = ref({})
    let shops = ref([])
    let bootData = reactive({
      id: null,
      shop_id: null,
      title: null,
      description_source: null,
      type_of_steps: 0,
      type_of_coupon: 0,
      coupon: null,
      url_to_activate: null,
      url_for_check: null,
      code_to_check: null,
      status: 0,
      partner_links: 1,
      auto_delete_date: null,
      upload_date: null,
      send_to_tg: 0,
      del: null,
    })
    let saveProcess = ref(false)
    let deleteProcess = ref(false)
    let published = ref(false)
    const router = useRouter()
    let noPermission = ref(false)

    let chainObject = {
      getId: () => {
        return bootData.id
      },
      getChannelId: () => {
        return 13
      },
      getStatus: () => {
        if (bootData.status === 10) {
          return "published"
        } else if (bootData.status === 0) {
          return "draft"
        } else {
          throw Error("Неопределен статус")
        }
      },
      getDel: () => {
        return bootData.del
      },
      getTypeId: () => {
        return cfg._("object_chains", "types", "promopushka")
      }
    }

    let commentModerationObject = {
      getTypeId: () => {
        return cfg._("comment_moderation", "types", "promopushka")
      }
    }

    let events = {
      onChangeTitle: (title) => {
        bootData.title = title
      },
      onChangeDescription: (description_source) => {
        bootData.description_source = description_source
      },
      onChangeShopId: (shop_id) => {
        bootData.shop_id = shop_id
      },
      onChangeTypeSteps: (type_of_steps) => {
        bootData.type_of_steps = type_of_steps ?? 0
        bootData.type_of_coupon = 0
        if (bootData.type_of_steps !== 20) {
          bootData.url_for_check = null
          bootData.code_to_check = null
        } 
      },
      onChangeUrlForCheck: (url_for_check) => {
        bootData.url_for_check = url_for_check
      },
      onChangeCodeToCheck: (code_to_check) => {
        bootData.code_to_check = code_to_check
      },
      onChangeTypeCoupon: (type_of_coupon) => {
        bootData.type_of_coupon = type_of_coupon ?? 0
      },
      onChangeUrlToActivate: (url_to_activate) => {
        bootData.url_to_activate = url_to_activate
      },
      onChangeCoupon: (coupon) => {
        bootData.coupon = coupon
      },
      onChangeStatus: (status) => {
        bootData.status = status ?? 0
      },
      onChangeIsPartnerLinks: (partner_links) => {
        bootData.partner_links = partner_links ?? 0
      },
      onChangeAutoDeleteDate: (val) => {
        bootData.auto_delete_date = val
      },
      onChangeUploadDate: (val) => {
        bootData.upload_date = val
      },
      onChangeSendToTg: (send_to_tg) => {
        bootData.send_to_tg = send_to_tg
      },
      onSave: () => {
        validations.value = []
        saveProcess.value = true
        if (bootData.id === null) {
          Api.create(bootData).then((resp) => {
            window.history.pushState(null, null, "/promopushka/edit/" + resp.item.id)
            if (bootData.partner_links === 1 && !resp.partnered) {
              Notify.error("Не удалось запартнерить ссылки");
            } else if (bootData.send_to_tg === 1 && !resp.sended_to_tg) {
              Notify.error("Не удалось отправить в телеграм канал");
            }

            couponId.value = resp.item.id

            initBootData(resp.item)
          }).then(() => {
            Notify.success("Сохранено")
          }).catch((err) => {
            Notify.error('Не удалось сохранить скидку', err)
            validations.value = err.response?.data?.validations || validations.value
          }).finally(() => {
            setTitle()
            saveProcess.value = false
          })
        } else {
          Api.update(bootData).then((resp) => {
            if (bootData.partner_links === 1 && !resp.partnered) {
              Notify.error("Не удалось запартнерить ссылки");
            } else if (bootData.send_to_tg === 1 && !resp.sended_to_tg) {
              Notify.error("Не удалось отправить в телеграм канал");
            }

            initBootData(resp.item)
          }).then(() => {
            Notify.success("Сохранено")
          }).catch((err) => {
            Notify.error('Не удалось сохранить скидку', err)
            validations.value = err.response?.data?.validations || validations.value
          }).finally(() => {
            saveProcess.value = false
          })
        }
      },
      onDelete: () => {
        if (!confirm('Вы уверены что хотите удалить скидку?')) return false;
        deleteProcess.value = true
        Api.delete(bootData.id).then(async () => {
          Notify.success("Скидка удалена")
          window.location.reload()
        }).catch((err) => {
          Notify.error('Не удалось удалить скидку', err)
        }).finally(() => {
          deleteProcess.value = false
        })
      },
      onDeleteRecovery: (val) => {
        if (val === true) {
          Notify.success("Скидка восстановлена")
          window.location.reload()
        }
      },
    }

    let conditions = {
      typeOfStepIsWithCheck: () => {
        return bootData.type_of_steps === 20
      },
      typeOfCouponIsOneToMany: () => {
        return bootData.type_of_coupon === 0
      },
      typeOfCouponIsOneToOneAndIsCreating: () => {
        return bootData.type_of_coupon === 10 && bootData.id === null
      },
      isReadyPage: () => {
        return readyPage.value === true
      },
      isPublished: () => {
        return published.value
      },
      isShowLoadingSaveBtn: () => {
        return saveProcess.value
      },
      isDisabledSaveBtn: () => {
        return saveProcess.value
      },
      needCouponList: () => {
        return bootData.id !== null && bootData.type_of_coupon === 10
      },
      isCreated: () => {
        return bootData.id !== null
      },
      isDeleted: () => {
        return bootData.del === 1
      },
      isDisabledDeleteBtn: () => {
        return deleteProcess.value
      },
      isShowLoadingDeleteBtn: () => {
        return deleteProcess.value
      },
      hasIdPost: () => {
        return bootData.id !== null
      },
      isNoPermission: () => {
        return noPermission.value === true
      },
    }

    function initBootData(data) {
      data.description_source = JSON.parse(data.description_source)
      data.send_to_tg = 0
      if (data.status === 10) {
        published.value = true
      }
      Object.assign(bootData, data)
    }

    watch(bootData, () => {
      PostStoreCommon.handle({
        "channel_id": 13,
        "route_name": "PromopushkaCreateUpdate",
      })
    })

    onMounted(async () => {
      if (route?.params?.id) {
        couponId.value = parseInt(route.params.id)
      }

      if (!checkPermission() && couponId.value) {
        await router.push({name: "CouponView", params: { id: route.params.id}})
        return
      } else if (!checkPermission() && !couponId.value) {
        setNoPermission()
      }

      readyPageOff()

      Api.shop().then(async (resp) => {
        shops.value = resp.items
      })

      setTitle()
      if (couponId.value !== null) {
        useMeta({title: getTitle()})
        Api.item(couponId.value).then(async (resp) => {
          initBootData(resp.item)
          readyPageOn()
        }).catch((err) => {
          Notify.error('Скидка не найдена', err)
        }).finally(() => {
          //readyPageOn()
        })
      } else {
        useMeta({title: getTitle()})
        readyPageOn()
      }
    })

    function setNoPermission() {
      noPermission.value = true
    }

    function readyPageOn() {
      readyPage.value = true
    }

    function readyPageOff() {
      readyPage.value = false
    }

    function setTitle() {
      if (couponId.value !== null) {
        titlePage.value = "Редактирование скидки"
      } else {
        titlePage.value = "Создание скидки"
      }
    }

    function getTitle() {
      return titlePage.value
    }

    function checkPermission() {
      return Permission.hasPermissions(channelPromopushkaCode)
    }

    function isNoPermission() {
      return noPermission.value === true
    }

    return {
      getTitle,
      conditions,
      events,
      validations,
      bootData,
      shops,
      chainObject,
      commentModerationObject,
      isNoPermission,
    }
  }
})
</script>

<style scoped>

</style>