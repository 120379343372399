import Items from "@/modules/promopushka/pages/coupons/Items/Items"
import Disabled from "@/modules/promopushka/pages/coupons/disabled/Disabled"
import authMiddleware from "@/plugins/router/middlewares/auth"
import CreateUpdate from "@/modules/promopushka/pages/edit/Index"
import View from "@/modules/promopushka/pages/view/Index"
import Shops from "@/modules/promopushka/pages/shops/Index"

// todo Префикс "Coupon" поменять в "Promopushka"
export default [{
    path: "/promopushka",
    name: "PromopushkaCouponItems",
    component: Items,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/promopushka/:id(\\d+)",
    name: "PromopushkaCouponItemsShop",
    component: Items,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/promopushka/edit/:id(\\d+)",
    name: "PromopushkaCouponUpdate",
    component: CreateUpdate,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/promopushka/create",
    name: "PromopushkaCouponCreate",
    component: CreateUpdate,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/promopushka/view/:id(\\d+)",
    name: "CouponView",
    component: View,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/promopushka/disabled",
    name: "CouponsDisabled",
    component: Disabled,
    meta: {
        middleware: authMiddleware,
    },
}, {
    path: "/promopushka/shops",
    name: "PromopushkaShops",
    component: Shops,
    meta: {
        middleware: authMiddleware,
    },
}]
