import Object from "./Object"

function buildData(isForever, reason, dateUntil) {
    return Object.buildData({
        is_forever: isForever,
        reason,
        date_until: dateUntil,
    })
}

export {buildData}
