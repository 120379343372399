<template>
  <div class="row">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title">Список купонов</h3>
        </div>
        <div class="table-responsive">
          <table class="table card-table">
            <thead>
              <tr>
                <th>Купон</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getCoupons().items" :key="item.coupon">
                <td>{{ item.coupon }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center p-1" v-if="isLoading()">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div class="card-footer">
          Всего: <b>{{ getCoupons().total }}</b>;
          Отдано: <b>{{ getCoupons().used }}</b>;
          Осталось: <b>{{ getCoupons().left }}</b>;
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref, watch, onMounted, reactive} from "vue"
import Api from "@/modules/promopushka/pages/edit/services/Api"

export default defineComponent({
  name: "CouponList",
  props: {
    coupon: {
      type: Number,
      required: true
    },
  },
  setup(props) {
    let couponId = ref(0)
    let coupons = reactive({
      items: [],
      total: 0,
      used: 0,
      left: 0
    })
    let loading = ref(true)

    onMounted(() => {
      couponId.value = props.coupon
      handleInit()
    })

    watch(() => props.coupon, (val) => {
      couponId.value = val
    })

    function getCoupon() {
      return couponId.value
    }

    function handleInit() {
      loading.value = true

      Api.statCouponList({id: couponId.value}).then((data) => {
        coupons.items = data.items
        coupons.total = data.coupon_total
        coupons.used = data.coupon_used
        coupons.left = data.coupon_left

        loading.value = false
      })
    }

    function getCoupons() {
      return coupons
    }

    function isLoading() {
      return loading.value
    }

    return {
      getCoupon,
      handleInit,
      getCoupons,
      isLoading,
    }
  },
})
</script>

<style scoped></style>
  