<template>
  <div class="datagrid-item">
    <div class="datagrid-title">Тип выдачи</div>
    <div class="datagrid-content mt-1"><b>{{ getVal() }}</b></div>
  </div>
</template>

<script>
import {defineComponent} from "vue"
import optionValues from "@/modules/promopushka/services/values"

export default defineComponent({
  name: "TypeSteps",
  props: {
    data: {
      type: String,
      require: true,
    },
  },
  setup(props) {
    function getVal() {
      let typeOfStepsId = props.data?.type_of_steps

      if (typeOfStepsId === undefined) {
        throw new Error("Не указан type_of_steps")
      }

      if (typeOfStepsId === null) {
        return "Не выбран"
      }

      let type = detect(typeOfStepsId)

      if (type === null) {
        return "Не определен"
      }

      return type.name
    }

    function detect(typeOfStepsId) {
      let items = optionValues.types.steps.filter((item) => {
        return item["id"] === typeOfStepsId
      })

      if (items.length === 0) {
        return null
      }

      return {
        name: items[0].name
      }
    }

    return {
      getVal,
    }
  }
})
</script>

<style scoped>

</style>
