<template>
  <div ref="createModal" class="modal fade" id="modal-ban" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ getTitle() }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Причина:</label>
                  <textarea v-model="fields.reason" class="form-control" rows="6" placeholder="Напишите причину бана"></textarea>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Период бана:</label>
                  <label class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="fields.forever_ban">
                    <span class="form-check-label">Навсегда</span>
                  </label>
                </div>

                <template v-if="!isForever()">
                  <div class="mb-3">
                    или
                  </div>
                  <div class="mb-3">
                    <label class="form-label">Действует до:</label>
                    <div class="input-icon mb-2 mt-2">
                      <Datepicker
                          auto-apply
                          @update:modelValue="onChangeDate"
                          v-model="fields.dateUntil"
                          locale="ru"
                          format="dd.MM.yyyy HH:mm"
                          placeholder="Выбрать дату"
                          :enable-time-picker="true"
                      />
                    </div>
                    <span>
                      <span class="me-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon text-blue">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                          <path d="M12 9h.01"></path><path d="M11 12h1v4h1"></path>
                        </svg>
                      </span>
                      <i>Дата и время должны быть указаны по московскому времени.</i>
                    </span>
                  </div>
                </template>
              </div>
            </div>
            <div class="card-footer bg-transparent mt-auto p-0 pt-3">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="btn-list justify-content-end">
                    <button class="btn btn-primary ms-auto" @click="handleBan">
                      <span class="spinner-border spinner-border-sm me-2" v-if="isPending()"></span>
                      {{ params.data.btn }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {reactive, ref, onMounted, defineComponent} from 'vue'
import createParams from "./services/params/create"
import updateParams from "./services/params/update"
import Datepicker from "@/plugins/datepicker"
import Notify from "@/services/Notify"
import {eventBus} from "@/plugins/event_bus/event_bus"
import {eventBind} from "./services/data"
import DT from "@/services/DT"

export default defineComponent({
  name: "Ban",
  components: {Datepicker},
  setup() {
    const createModal = ref(null);
    const fields = reactive(getFields())

    let bootModal = null
    let params = ref(getParams())

    let pending = ref(false)

    function getParams() {
      return {
        data: {
          title: "",
          page: "",
        }
      }
    }

    function resetParams() {
      params.value = getParams()
    }

    function getFields() {
      return {
        user_id: null,
        user_name: "",
        ban_id: null,
        ban_name: '',
        reason: '',
        dateUntil: null,
        dateUntilToSend: '',
        forever_ban: false,
        ban_success: false,
        source_id: null,
      }
    }

    function closeModal() {
      let onlyClose = isBanFail()
      runEvent(onlyClose, getBanId())
      clearForm()
    }

    function isBanFail() {
      return fields.ban_success === false
    }

    function runEvent(onlyClose, banId) {
      eventBus.emit(eventBind, {
        only_close: onlyClose,
        ban_id: banId,
      })
    }

    async function showModal(event) {
      const button = event.relatedTarget
      const type = button.getAttribute('data-type')

      // это оставлено на случай, если пригодится такое условие
      // не стал выпиливать, так как это было уже написано ранее
      if (type === "create") {
        setParams(createParams)
        create(event)
      } else {
        setParams(updateParams)
        update(event)
      }
    }

    function setParams(val) {
      params.value = val
    }

    function create() {}

    function update(event) {
      const button = event.relatedTarget
      const object = JSON.parse(button.getAttribute('data-object'))

      updateFields({
        user_id: object.user_id,
        user_name: object.user_name,
        ban_id: object.ban_id,
        ban_name: object.ban_name,
        source_id: object.source_id,
      })
    }

    function updateFields({user_id, user_name, ban_id, ban_name, source_id}) {
      fields.user_id = user_id
      fields.user_name = user_name
      fields.ban_id = ban_id
      fields.ban_name = ban_name
      fields.source_id = source_id
    }

    function preShowModal() {
      resetParams()
    }

    onMounted(() => {
      bootModal = new bootstrap.Modal(createModal.value);
      createModal.value.addEventListener('shown.bs.modal', showModal)
      createModal.value.addEventListener('show.bs.modal', preShowModal)
      createModal.value.addEventListener('hidden.bs.modal', closeModal)
    })

    function clearForm() {
      let items = getFields()
      for (let key of Object.keys(items)) {
        fields[key] = items[key]
      }
    }

    function hasUser() {
      return fields.user_id !== null
    }

    function getTitle() {
      let result = ""

      result += params.value.data.title

      if (hasUser()) {
        result += ": "
        result += "\"" + fields.ban_name + "\""
        result += ", "
        result += "для пользователя: "
        result += "\"" + fields.user_name + "\""
      }

      return result
    }

    function onChangeDate(date) {
      // todo не давать выбирать дату и время меньше чем указанно в параметре календаря (откуда-то считывать дату по MSK)

      let dtClone = new Date(date)
      let dt = (new DT(dtClone)).correctTZSet()

      fields.dateUntilToSend = dt.get("%Y-%M-%D %H:%I:%S")

      fields.dateUntil = date
    }

    function isForever() {
      return fields.forever_ban === true
    }

    function handleBan() {
      markPending()

      let dateUntil = fields.dateUntilToSend

      if (fields.forever_ban === true) {
        dateUntil = ""
      }
      
      let payload = {
        "user_id": getUserId(),
        "ban_id": getBanId(),
        "is_forever": getForeverBan(),
        "until_at": dateUntil,
        "reason_desc": getReason(),
        "source_id": getSourceId(),
      }

      params.value.action({
        payload,
      }).then((resp) => {
        markBanSuccess()
        bootModal.hide()
        Notify.success(resp.msg)
      }).catch((resp) => {
        Notify.error(resp.msg)
      }).finally(() => {
        markUnpending()
      })
    }

    function isPending() {
      return pending.value === true
    }

    function markPending() {
      return pending.value = true
    }

    function markUnpending() {
      return pending.value = false
    }

    function getUserId() {
      return fields.user_id
    }

    function getForeverBan() {
      return fields.forever_ban
    }

    function getReason() {
      return fields.reason
    }

    function getSourceId() {
      return fields.source_id
    }

    function markBanSuccess() {
      fields.ban_success = true
    }

    function getBanId() {
      return fields.ban_id
    }

    return {
      getTitle,
      bootModal,
      createModal,
      onChangeDate,
      fields,
      isForever,
      params,
      handleBan,
      isPending,
    }
  }
})
</script>
