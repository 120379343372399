import ApiBase from "../Api"

export default {
    data: {
        title: "Бан",
        page: "update",
        btn: "Забанить",
    },
    action: ({payload}) => {
        return new Promise((resolve, reject) => {
            return ApiBase.bindBan(payload).then(() => {
                resolve({
                    msg: "Пользователь забанен",
                })
            }).catch(() => {
                reject({
                    msg: "Ошибка бана",
                })
            })
        })
    }
}
