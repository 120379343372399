<template>
  <div id="bans" class="accordion">
    <p class="text-secondary">Будет запрещено:</p>
    <template v-if="isReady()">
      <div v-for="ban in getBans()" :key="row.getBanId(ban)">
        <div class="form-check form-switch d-inline-block mt-2">
          <input
              :id="getLabelId(row.getBanId(ban))"
              :data-ban_id="row.getBanId(ban)"
              :data-source_id="row.getSourceId(ban)"
              :checked="row.isChecked(ban)"
              v-model='banData[row.getBanId(ban)]["checked"]'
              class="form-check-input"
              type="checkbox"
              @change="change"
          />
          <label class="form-check-label" :for="getLabelId(row.getBanId(ban))">{{ row.getBanName(ban) }}</label>
          <span
              data-bs-toggle="modal"
              data-type="update"
              data-bs-target="#modal-ban"
              :id="getModalBanId(row.getBanId(ban))"
              :data-object='getObject({
                ban_id: row.getBanId(ban),
                ban_name: row.getBanName(ban),
                source_id: row.getSourceId(ban)
              })'
          ></span>
          <template v-if="row.getBanChecked(ban)">
            <BanInfo :data="banInfoBuildData(
                row.getIsForever(ban),
                row.getReasonDesc(ban),
                row.getUntilAt(ban),
            )"/>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex justify-content-center">
        <div class="spinner-border"></div>
      </div>
    </template>
  </div>
  <Teleport to="body">
    <Ban />
  </Teleport>
</template>

<script>
import './css/style.css'
import {defineComponent, onMounted, onUnmounted, reactive, ref} from "vue"
import element from "./services/element"
import Ban from "./components/ban/Index"
import Object from "./services/Object"
import Api from "./services/Api"
import Notify from "@/services/Notify"
import {eventBus} from "@/plugins/event_bus/event_bus"
import {eventBind} from "./components/ban/services/data"
import BanInfo from "./components/ban_info/Index"
import {buildData as banInfoBuildData} from "./components/ban_info/services/ban_info"

export default defineComponent({
  name: "Bans",
  components: {
    Ban,
    BanInfo,
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const fields = reactive({
      user_id: null,
      user_name: "",
    })
    let bans = ref({})
    let ready = ref(false)
    let banData = ref({})
    let row = {
      getBanId(item) {
        return element.getBanId(item)
      },
      getBanCode(item) {
        return element.getBanCode(item)
      },
      getBanChecked(item) {
        return element.getBanChecked(item)
      },
      getBanName(item) {
        return element.getBanName(item)
      },
      isChecked(item) {
        return getBanData(element.getBanId(item), "checked")
      },
      getSourceId(item) {
        return element.getSourceId(item)
      },
      getIsForever(item) {
        return element.getIsForver(item)
      },
      getReasonDesc(item) {
        return element.getReasonDesc(item)
      },
      getUntilAt(item) {
        return element.getUntilAt(item)
      },
    }

    async function load() {
      unmarkReady()
      Api.bans(getUserId()).then((resp) => {
        let items = resp?.items

        if (items === undefined) {
          throw new Error("Ошибка получения банов")
        }

        let result = []
        items.forEach((item) => {
          result.push(item)

          setBanData(item["ban_id"], {
            checked: item["ban_checked"],
          })
        })

        setBans(items)
      }).catch(() => {
        Notify.error("Не удалось загрузить баны")
      }).finally(() => {
        markReady()
      })
    }

    function setBanData(key, data) {
      banData.value[key] = data
    }

    function getBanData(id, key) {
      return banData.value[id][key]
    }

    onMounted(async () => {
      updateFields(props.user)
      if (hasUser()) {
        load().then(() => {
          bindListener(bindListenBan)
        })
      }
    })

    onUnmounted(() => {
      unbindListener(bindListenBan)
    })

    function bindListener(fn) {
      eventBus.on(eventBind, fn)
    }

    function unbindListener(fn) {
      eventBus.off(eventBind, fn)
    }

    function bindListenBan(payload) {
      let onlyClose = payload?.only_close
      let banId = payload?.ban_id

      if (onlyClose === undefined || banId === undefined) {
        throw new Error("Получены не корректные данные")
      }

      if (onlyClose === true) {
        setBanData(banId, {
          checked: false,
        })
      } else if (onlyClose === false) {
        load()
      }
    }

    function getUserId() {
      return fields.user_id
    }

    function hasUser() {
      return getUserId() !== null
    }

    function getUserName() {
      return fields.user_name
    }

    function updateFields(query) {
      fields.user_id = query.id
      fields.user_name = query.name
    }

    function setBans(val) {
      bans.value = val
    }

    function getBans() {
      return bans.value
    }

    function change(e) {
      if (e.target.checked === false && !confirm("Уверены?")) {
        e.target.checked = true
        return false
      }

      let banId = parseInt(e.target.dataset.ban_id)
      let sourceId = parseInt(e.target.dataset.source_id)

      if (e.target.checked) {
        showModal(getModalBanId(banId))
      } else {
        Api.unbindBan(getUserId(), banId, sourceId).then(() => {
          load().then(() => {
            Notify.success("Бан был снят с пользователя")
          })
        }).catch(() => {
          e.target.checked = true
          Notify.error("Не удалось отнять бан")
        })
      }
    }

    function showModal(elementId) {
      const modal = document.getElementById(elementId)
      modal.click()
    }

    function unmarkReady() {
      ready.value = false
    }

    function markReady() {
      ready.value = true
    }

    function isReady() {
      return ready.value === true
    }

    function getLabelId(banId) {
      return 'ban-' + banId
    }

    function getObject(data) {
      return Object.getData({
        user_id: getUserId(),
        user_name: getUserName(),
        ban_id: data.ban_id,
        ban_name: data.ban_name,
        source_id: data.source_id,
      })
    }

    function getModalBanId(banId) {
      return "modal-ban-" + banId
    }

    return {
      getBans,
      row,
      change,
      isReady,
      getLabelId,
      getObject,
      getModalBanId,
      banData,
      banInfoBuildData,
    }
  }
})
</script>

<style>

</style>
