<template>
  <div id="statistic-title" class="statistic">
    <template v-if="hasAnyData()">
      <template v-if="element.isPost()">
        <div class="statistic-item" v-if="element.actions.post.hasCountClick()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-pointer" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M7.904 17.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l4.907 4.907a1.067 1.067 0 0 0 1.509 0l1.047 -1.047a1.067 1.067 0 0 0 0 -1.509l-4.907 -4.907l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563z"></path>
          </svg>
          <span class="count">{{ element.actions.post.getCountClick() }}</span>
        </div>

        <div class="statistic-item statistic-item_pointer" v-if="element.actions.post.hasCountSell()" @click="element.events.post.onCountSell()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shopping-cart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M17 17h-11v-14h-2"></path>
            <path d="M6 5l14 1l-1 7h-13"></path>
          </svg>
          <span class="count">{{ element.actions.post.getCountSell() }}</span>
        </div>
      </template>

      <template v-if="element.isChain()">
        <div class="statistic-item statistic-item_yellow" v-if="element.actions.chain.hasCountClick()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-pointer" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M7.904 17.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l4.907 4.907a1.067 1.067 0 0 0 1.509 0l1.047 -1.047a1.067 1.067 0 0 0 0 -1.509l-4.907 -4.907l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563z"></path>
          </svg>
          <span class="count">{{element.actions.chain.getCountClick() }}</span>
        </div>

        <div class="statistic-item statistic-item_pointer statistic-item_yellow" v-if="element.actions.chain.hasCountSell()" @click="element.events.chain.onCountSell()">
          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-shopping-cart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M17 17h-11v-14h-2"></path>
            <path d="M6 5l14 1l-1 7h-13"></path>
          </svg>
          <span class="count">{{ element.actions.chain.getCountSell() }}</span>
        </div>
      </template>

      <div class="statistic-item statistic-item_yellow statistic-item_pointer" v-if="element.actions.chain.hasCount()" @click="element.events.chain.onChain()">
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-share" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M6 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
          <path d="M18 6m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
          <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
          <path d="M8.7 10.7l6.6 -3.4" />
          <path d="M8.7 13.3l6.6 3.4" />
          <path v-if="element.isChain()" d="M3 3l18 18" />
        </svg>
        <span class="count">{{ element.actions.chain.getCount() }}</span>
      </div>

    </template>

    <div class="statistic-item statistic-item_gray" v-if="isViewPoint()">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-medal-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 3h6l3 7l-6 2l-6 -2z" /><path d="M12 12l-3 -9" /><path d="M15 11l-3 -8" /><path d="M12 19.5l-3 1.5l.5 -3.5l-2 -2l3 -.5l1.5 -3l1.5 3l3 .5l-2 2l.5 3.5z" /></svg>
      <span class="count">{{ element.actions.post.getPoint() }}</span>
    </div>

  </div>
</template>

<script>
import "./css/style.css"
import {defineComponent, ref, onMounted} from "vue"
import Api from "./services/Api"
import cfg from "@/services/Config"

export default defineComponent({
  name: "Main",
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    const postType = "post"
    const chainType = "chain"

    let types = [
      postType,
      chainType,
    ]

    let data = {
      post: {
        countClick: ref(0),
        countSell: ref(0),
        point: ref(0),
      },
      chain: {
        countClick: ref(0),
        countSell: ref(0),
        countItems: ref(0),
      }
    }

    let element = {
      current: ref(postType),
      click: {
        post: {
          countSell: ref(false),
        },
        chain: {
          countSell: ref(false),
        }
      },
      events: {
        post: {
          onCountSell: () => {
            onCountSellEmit({
              type: postType,
              show: element.toggleCountSell(postType)
            });
          },
        },
        chain: {
          onCountSell: () => {
            onCountSellEmit({
              type: chainType,
              show: element.toggleCountSell(chainType)
            });
          },
          onChain: () => {
            if (element.isCurrentElement(postType)) {
              element.showElement(chainType)
            } else {
              element.showElement(postType)
            }

            onClickChainEmit({
              type: element.isCurrentElement(postType) ? postType : chainType,
              show: element.toggleCountSell(chainType)
            });
          }
        }
      },
      actions: {
        post: {
          hasCountClick: () => {
            return hasValue(data.post.countClick.value)
          },
          hasCountSell: () => {
            return hasValue(data.post.countSell.value)
          },
          getCountClick: () => {
            return data.post.countClick.value
          },
          getCountSell: () => {
            return data.post.countSell.value
          },
          setCountClick: (val) => {
            data.post.countClick.value = val
          },
          setCountSell: (val) => {
            data.post.countSell.value = val
          },
          getPoint: () => {
            return data.post.point.value
          },
          setPoint: (val) => {
            data.post.point.value = val
          },
          hasPoint: () => {
            return data.post.point.value !== 0
          },
        },
        chain: {
          hasCountClick: () => {
            return hasValue(data.chain.countClick.value)
          },
          hasCountSell: () => {
            return hasValue(data.chain.countSell.value)
          },
          hasCount: () => {
            return hasValue(element.actions.chain.getCount())
          },
          getCountClick: () => {
            return data.chain.countClick.value
          },
          getCountSell: () => {
            return data.chain.countSell.value
          },
          getCount: () => {
            return data.chain.countItems.value
          },
          setCountClick: (val) => {
            data.chain.countClick.value = val
          },
          setCountSell: (val) => {
            data.chain.countSell.value = val
          },
          setCountItems: (val) => {
            data.chain.countItems.value = val
          },
        }
      },
      toggleCountSell: (type) => {
        for (let val of types.entries()) {
          let nextType = val[1];
          if (type === nextType) {
            element.click[type].countSell.value = !element.click[type].countSell.value
          } else {
            element.click[nextType].countSell.value = false
          }
        }
        return element.click[type].countSell
      },
      isPost: () => {
        return element.isCurrentElement(postType)
      },
      isChain: () => {
        return element.isCurrentElement(chainType)
      },
      isCurrentElement: (type) => {
        return element.current.value === type
      },
      showElement: (type) => {
        element.current.value = type
      }
    }

    onMounted(() => {
      render()
    })

    function onCountSellEmit({type, show}) {
      emit("onCountSell", {
        "post_id": props.data["post_id"],
        "element": {
          "type": type,
          "show": show
        },
      })
    }

    function onClickChainEmit({type, show}) {
      emit("onClickChain", {
        "post_id": props.data["post_id"],
        "element": {
          "type": type,
          "show": show
        },
      })
    }

    function render() {
      getByPost(props.data["post_id"])
      getByChain(props.data["post_id"])
      getPoint(props.data["post_id"])
    }

    function getPoint(postId) {
      let ids = []
      ids.push(postId)
      Api.statisticPostsPoint(ids, getStatisticTypeId()).then((resp) => {
        let item = resp[postId]
        if (item?.val) {
          element.actions.post.setPoint(item["val"]);
        }
      });
    }

    function getByPost(postId) {
      let ids = []
      ids.push(postId)
      Api.statisticPosts(ids, getStatisticTypeId()).then((resp) => {
        let item = resp[postId]
        element.actions.post.setCountClick(item["count_click"]);
        element.actions.post.setCountSell(item["count_sell"]);
      });
    }

    function getByChain(postId) {
      let ids = []
      ids.push(postId)
      Api.statisticPostsChain(ids, getStatisticTypeId()).then((resp) => {
        let item = resp[postId]
        element.actions.chain.setCountClick(item["count_click"]);
        element.actions.chain.setCountSell(item["count_sell"]);
        element.actions.chain.setCountItems(item["count"]);
      });
    }

    function hasAnyData() {
      return (
          data.post.countClick.value !== 0 || data.post.countSell.value !== 0
      ) || (
          data.chain.countItems.value !== 0
      )
    }

    function hasValue(val) {
      return val !== 0
    }

    function isViewPoint() {
      if (!element.actions.post.hasPoint()) {
        return false
      }

      return true
    }

    function getStatisticTypeId() {
      return cfg._("statistic", "types", "post")
    }

    return {
      hasAnyData,
      element,
      isViewPoint,
    }
  }
})
</script>

<style scoped></style>
