<template>
  <div class="datagrid-item">
    <div class="datagrid-title">Дата обновления</div>
    <div class="datagrid-content mt-1"><b>{{ getVal() }}</b></div>
  </div>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({
  name: "UploadDate",
  props: {
    data: {
      type: String,
      require: true,
    },
  },
  setup(props) {
    function getVal() {
      if (props.data["upload_date"] === null) {
        return "Нет"
      }

      return props.data["upload_date"]
    }

    return {
      getVal,
    }
  }
})
</script>

<style scoped>

</style>
