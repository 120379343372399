import AuthStore from "@/modules/auth/services/Store"
import Helper from "@/services/Helper"

export default class Permission {
    hasPermissions(permissions) {
        if (Helper.isString(permissions)) {
            permissions = [permissions]
        } else if (!Helper.isArray(permissions)) {
            return false
        }

        return Helper.inArray(permissions, AuthStore.getPermissions())
    }

    hasRoles(roles) {
        if (Helper.isString(roles)) {
            roles = [roles]
        } else if (!Helper.isArray(roles)) {
            return false
        }

        if (!AuthStore.isAuth()) {
            return false
        }

        let data = AuthStore.getUserData()

        return Helper.inArray(roles, [data["role_code"]])
    }

    isShowPermissionBtn() {
        return this.hasRoles("admin")
    }
}
