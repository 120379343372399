export default {
    data: {
        title: "",
        page: "update",
        btn: "",
    },
    action: () => {
        return new Promise(() => {

        })
    }
}
