<template>
  <template v-if="isShow()">
    <div>
      <span class="me-1">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon text-blue">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
          <path d="M12 9h.01"></path><path d="M11 12h1v4h1"></path>
        </svg>
      </span>
      <span>
        <template v-if="fields.data.isForever()">
          <span><b>Навсегда</b></span>
        </template>
        <template v-if="!fields.data.isForever() && fields.data.hasUntilDate()">
          <span><b v-html="fields.data.getUntilDate()"></b></span>
        </template>
        <template v-if="fields.data.hasReason()">
          <span>, {{ fields.data.getReason() }}</span>
        </template>
      </span>
    </div>
  </template>
</template>

<script>
import {defineComponent, onMounted, ref} from 'vue'
import Object from "./services/Object"

export default defineComponent({
  name: "BanInfo",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    let fields = ref({})

    onMounted(() => {
      fields.value = {
        data: Object.getData(props.data)
      }
    })

    function isShow() {
      return fields?.value?.data !== undefined
    }

    return {
      fields,
      isShow,
    }
  }
})
</script>
