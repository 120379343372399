<template>
  <div class="datagrid-item">
    <div class="datagrid-title">Ссылка для активации</div>
    <div class="datagrid-content mt-1"><b>{{ getVal() }}</b></div>
  </div>
</template>

<script>
import {defineComponent} from "vue"

export default defineComponent({
  name: "UrlActivate",
  props: {
    data: {
      type: String,
      require: true,
    },
  },
  setup(props) {
    function getVal() {
      if (props.data["url_to_activate"] === "" || props.data["url_to_activate"] === null) {
        return "Пусто"
      }

      return props.data["url_to_activate"]
    }

    return {
      getVal,
    }
  }
})
</script>

<style scoped>

</style>
