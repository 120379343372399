<template>
  <div class="card-body" id="title">
    <div class="divide-y">
      <div class="row">
        <span class="col-auto content">
          <span class="form-label-title">Заголовок:</span> <span class="form-label-value">{{ getVal() }}</span>
        </span>
      </div>
    </div>
  </div>
</template>
  
<script>
import {defineComponent} from "vue"
import "./css/style.css"

export default defineComponent({
  name: "Title",
  props: {
    data: {
      type: String,
      require: true,
    },
  },
  setup(props) {
    function getVal() {
      return props.data["title"]
    }

    return {
      getVal,
    }
  }
})
</script>

<style scoped>

</style>
