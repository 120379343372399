<template>
  <button class="btn btn-dark w-100 mb-3" @click="onCancel">
    <span class="spinner-border spinner-border-sm me-2" v-show="isShowLoadingBtn()"></span>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-invoice">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
      <path d="M19 12v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-14a2 2 0 0 1 2 -2h7l5 5v4.25" />
    </svg>
    В черновик
  </button>
</template>

<script>
import {defineComponent, ref} from "vue"
import Api from "./services/Api"
import Notify from "@/services/Notify"

export default defineComponent({
  name: "InDraft",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    let canceling = ref(false)

    let postId = ref(props.data["post_id"])

    function onCancel() {
      if (confirm("Вы точно хотите изменить статус на \"Черновик\"?")) {
        startCanceling()

        Api.draft(postId.value).then(() => {
          emit('onDraft', true)
        }).then(() => {
          Notify.success("Пост теперь в черновиках")
        }).catch(() => {
          emit('onDraft', false)
        }).finally(() => {
          stopCanceling()
        })
      }
    }

    function isDisabledBtn() {
      return canceling.value === true
    }

    function isShowLoadingBtn() {
      return isDisabledBtn()
    }

    function startCanceling() {
      canceling.value = true
    }

    function stopCanceling() {
      canceling.value = false
    }

    return {
      onCancel,
      isDisabledBtn,
      isShowLoadingBtn,
    }
  },
})
</script>

<style scoped>

</style>