export default class Object {
    static getData({user_id, user_name, ban_id, ban_name, source_id}) {
        return JSON.stringify({
            user_id,
            user_name,
            ban_id,
            ban_name,
            source_id,
        })
    }
}
