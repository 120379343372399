<template>
  <metainfo></metainfo>
  <template v-if="isForbiddenPage()">
    <NoPermission />
  </template>
  <template v-else>
    <div class="container-xl">
      <div class="page-header d-print-none">
        <div class="row g-2 align-items-center">
          <div class="col-sm">
            <h2 class="page-title">
              Комментарии
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div class="mb-3">
          <CommentFilter @onFilter="onFilter" :payload="filter.payload"/>
        </div>
        <div class="mb-3">
          <CommentModeration :payload="query"/>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import CommentModeration from "@/components/comment_moderation/CommentModeration"
import CommentFilter from "@/modules/comments/components/filter/Filter"
import Helper from "@/services/Helper"
import {onMounted, ref} from "vue"
import {useMeta} from "vue-meta"
import NoPermission from "@/modules/auth/pages/no_permission/Index"
import Permission from "@/modules/users/services/Permission"
import {
  commentsCode,
} from "@/modules/auth/services/permissions"

export default {
  name: "Comment",
  components: {
    CommentModeration,
    CommentFilter,
    NoPermission,
  },
  setup() {
    useMeta({title: "Комментарии"})

    let query = ref({})
    let noPermission = ref(false)

    let filter = {
      payload: {
        search: "",
        date: null,
        users: [],
        channels: [],
      },
    }

    onMounted(() => {
      if (!checkPermission()) {
        return
      }
    })


    function checkPermission() {
      if (!Permission.hasPermissions(commentsCode)) {
        setNoPermission()
        return false
      }

      return true
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function isForbiddenPage() {
      return noPermission.value
    }

    function onFilter(payload) {
      payload = Helper.mergeObject(payload, {
        categories: ['all', 'new', 'approved', 'rejected', 'removed'],
        limit: 50,
        active_category: 'new',
        force_show: true,
        filter: {
          user_ids: payload.users ?? [],
          channels: payload.channels ?? [],
          from_date: payload.date?.from ?? "",
          to_date: payload.date?.to ?? "",
          query_string: payload.search ?? "",
        },
      })

      payload = Helper.clone(payload)

      query.value = payload
    }

    return {
      onFilter,
      filter,
      query,
      isForbiddenPage,
    }
  },
}
</script>

<style scoped>

</style>
