<template>
  <div ref="createModal" class="modal fade" id="modal-comment_moderation-bans" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ getTitle() }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModalForce"></button>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <template v-if="isShowBans()">
                <Bans :user="{
                  id: getUserId(),
                  name: getUserName(),
                }" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./css/style.css"
import {reactive, ref, onMounted, defineComponent} from 'vue'
import createParams from "./services/params/create"
import updateParams from "./services/params/update"
import Bans from "@/components/bans/Bans"
import ObjectHelper from "./services/Object"

export default defineComponent({
  name: "ComponentModerationBansModal",
  components: {
    Bans,
  },
  setup() {
    const createModal = ref(null);
    const fields = reactive(getFields())

    let bootModal = null
    let params = ref(getParams())

    let forceCloseModal = ref(false)

    function getParams() {
      return {
        data: {
          title: "",
          page: "",
        }
      }
    }

    function resetParams() {
      params.value = getParams()
    }

    function getFields() {
      return {
        user_id: null,
        user_name: "",
      }
    }

    function closeModal() {
      clearForm()
    }

    async function showModal(event) {
      const button = event.relatedTarget
      const type = button.getAttribute('data-type')

      // это оставлено на случай, если пригодится такое условие
      // не стал выпиливать, так как это было уже написано ранее
      if (type === "create") {
        setParams(createParams)
        create(event)
      } else {
        setParams(updateParams)
        update(event)
      }
    }

    function setParams(val) {
      params.value = val
    }

    function create() {}

    function update(event) {
      const button = event.relatedTarget
      const object = ObjectHelper.getData(button.getAttribute('data-object'))

      updateFields({
        user_id: object.getUserId(),
        user_name: object.getUserName(),
      })
    }

    function updateFields({user_id, user_name}) {
      fields.user_id = user_id
      fields.user_name = user_name
    }

    function preShowModal() {
      resetParams()
    }

    onMounted(() => {
      bootModal = new bootstrap.Modal(createModal.value);
      createModal.value.addEventListener('shown.bs.modal', showModal)
      createModal.value.addEventListener('show.bs.modal', preShowModal)
      createModal.value.addEventListener('hidden.bs.modal', closeModal)
      createModal.value.addEventListener('hide.bs.modal', hideModal)
    })

    function hideModal(event) {
      if (forceCloseModal.value === true) {
        forceCloseModal.value = false
      } else {
        event.preventDefault()
      }
    }

    function closeModalForce() {
      forceCloseModal.value = true
      clearForm()
      bootModal.hide()
    }

    function clearForm() {
      let items = getFields()
      
      for (let key of Object.keys(items)) {
        fields[key] = items[key]
      }
    }

    function hasUser() {
      return fields.user_id !== null
    }

    function getUserId() {
      return fields.user_id
    }

    function getUserName() {
      return fields.user_name
    }

    function getTitle() {
      let result = ""

      if (hasUser()) {
        result += "Забанить пользователя: "
        result += "\"" + fields.user_name + "\""
      }

      return result
    }

    function isShowBans() {
      return hasUser()
    }

    return {
      getTitle,
      bootModal,
      createModal,
      fields,
      params,
      isShowBans,
      getUserId,
      getUserName,
      closeModalForce,
    }
  }
})
</script>
