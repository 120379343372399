import VueAxios from "vue-axios"
import axios from "axios"
//import Notify from "@/services/Notify"
import Helper from "@/services/Helper"
//import ApiBase from "@/modules/auth/services/ApiBase"
import cfg from "@/services/Config"
import Err from "@/services/err/Err"
import Notify from "@/services/Notify"
import ApiBase from "@/modules/auth/services/ApiBase"
import Auth from "@/modules/auth/services/Auth"

axios.defaults.baseURL = Helper.getApiUrl()

axios.interceptors.request.use((request) => {
    let token = Auth.getToken()
    let client = Auth.getClient()

    request.headers['Authorization'] = `Bearer ${token}`
    request.headers['Client'] = client

    return request
})

let timeoutId;

axios.interceptors.response.use(function (response) {
    return response;
}, function (resp) {
    if (resp.response.status === 401) {
        let token = Auth.getToken()
        let client = Auth.getClient()

        if (!token || !client) {
            return Promise.reject(resp)
        }

        return new Promise((resolve, reject) => {
            // todo Функционал refresh используется еще в других местах
            // переписать, чтобы это было в 1м месте в виде вызова класса из всех мест
            ApiBase.refresh({
                token,
                client,
            }).then((response) => {
                const item = response.data["item"]

                Auth.setToken(item["token"])
                Auth.setDataLogin(item)

                axios.request(resp.config).then(resolve).catch(reject);
            }).catch(() => {
                reject(resp)
            })
        })
    } else {
        const data = resp.response.data;

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        if (data['status_code'] !== 0 && data['msg'] !== undefined) {
            let url = resp.request.responseURL
            let key = Helper.md5(url + resp.config.method)
            let path = getPath(url);
            let excludeRouters = cfg._("http", "err", "exclude_for_view")

            if (!Helper.inArray([path], excludeRouters)) {
                Err.add(key, data['msg'])
            }

            if (data?.status_code === 2 && data?.validations !== undefined) {
                let validations = data?.validations
                for (const key in validations) {
                    if (Array.isArray(validations[key])) {
                        validations[key].forEach((message) => {
                            let hash = Helper.md5(url + resp.config.method + key)
                            Err.add(hash, message)
                        });
                    }
                }
            }

            timeoutId = setTimeout(() => {
                const uniqueValues = new Set(Err.getAll().values());

                let msg = ""
                for (const val of uniqueValues) {
                    msg += val + "\n"
                }
                
                if (msg) {
                    Notify.error(msg)
                }
            }, 500)
        }

        return Promise.reject(resp)
    }
});

function getPath(url) {
    const link = new URL(url);
    return link.pathname;
}

export {VueAxios, axios}
