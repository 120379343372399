import {axios as http} from "@/plugins/http"
import Helper from "@/services/Helper"

export default class ApiBase {
    static user(id) {
        return http.post("/api/v1/user/user/user", {
            "user_id": id,
        });
    }

    static userChangeActive(id, query) {
        query = Helper.mergeObject(query, {
            "user_id": id,
        })

        return http.post("/api/v1/user/user/change-active", query);
    }

    static userChangeAvatar(query) {
        return http.post("/api/v1/user/user/change-avatar", query);
    }

    static userDeleteAvatar(id) {
        return http.post("/api/v1/user/user/delete-avatar", {
            "user_id": id,
        });
    }

    static userBindPermission(query) {
        return http.post("/api/v1/user/user/bind-permission", query);
    }

    static userUnbindPermission(userId, permissionId, sourceId) {
        return http.post("/api/v1/user/user/unbind-permission", {
            "user_id": userId,
            "permission_id": permissionId,
            "source_id": sourceId,
        });
    }

    static users(query) {
        return http.post("/api/v1/user/user/users", query);
    }

    static phantoms(query) {
        return http.post("/api/v1/user/phantom/phantoms", query);
    }

    static userCreate(query) {
        return http.post("/api/v1/user/user/create", query);
    }

    static phantomCreate(query) {
        return http.post("/api/v1/user/phantom/create", query);
    }

    static userEdit(id, query) {
        query.append("user_id", id)

        return http.post("/api/v1/user/user/edit", query);
    }

    static phantomEdit(id, query) {
        query.append("user_id", id)

        return http.post("/api/v1/user/phantom/edit", query);
    }

    static bindUsersToPhantom(id, query) {
        query.append("user_id", id)

        return http.post("/api/v1/user/phantom/bind-users-to-phantom", query);
    }

    static getUsersToPhantom(phantomId) {
        return ApiBase.users({
            "limit": 1000,
            "fantom_ids": phantomId,
        })
    }

    static bindPhantomsToUser(id, query) {
        query.append("user_id", id)

        return http.post("/api/v1/user/user/bind-phantoms-to-user", query);
    }

    static getPhantomsToUser(id) {
        return http.post("/api/v1/user/user/phantoms-to-user", {
            "user_id": id,
        });
    }

    static allPermissions(query) {
        return http.post("/api/v1/user/all-permissions", query);
    }

    static permissionGroups() {
        return http.post("/api/v1/user/permission-groups");
    }

    static groupPermissions(query) {
        return http.post("/api/v1/user/group-permissions", query);
    }

    static bansToUser(userId) {
        return http.post("/api/v1/user/user/bans-to-user", {
            "user_id": userId,
        });
    }

    static userUnbindBan(userId, banId, sourceId) {
        return http.post("/api/v1/user/user/unbind-ban", {
            "user_id": userId,
            "ban_id": banId,
            "source_id": sourceId,
        });
    }

    static userBindBan(query) {
        return http.post("/api/v1/user/user/bind-ban", query);
    }
}
