<template>
  <metainfo></metainfo>
  <template v-if="isForbiddenPage()">
    <NoPermission />
  </template>
  <template v-else>
    <div class="page-header d-print-none">
      <div class="container-xl">
        <div class="row g-2 align-items-center">
          <div class="col-sm">
            <Title :title="'Фантомы'" :count="countPhantoms" />
          </div>
          <div class="col-auto">
            <span class="me-2">
              <button class="btn btn-primary" data-bs-toggle="modal" data-type="create" :data-bs-target="'#modal-phantom'">
                <IconCirclePlus />
                Создать фантом
              </button>
            </span>
            <span>
              <router-link class="btn btn-dark" :to="{name: 'Users',}">
                Пользователи
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div class="mb-3">
          <PhantomFilter @onFilter="onFilter" :params="getParams()" />
        </div>
        <div ref="scrollComponent">
          <PhantomList :query="getQuery()" @onRender="onRender" :clear-result="getClearResult()" />
        </div>
      </div>
    </div>
    <Teleport to="body">
      <Modal @onUpdate="onUpdate" @onCreate="onCreate" @onDelete="onDelete"/>
    </Teleport>
  </template>
</template>

<script>
import {defineComponent, onMounted, onUnmounted, ref} from 'vue'
import PhantomFilter from "@/modules/users/pages/phantoms/components/filter/Index"
import PhantomList from "@/modules/users/pages/phantoms/components/list/Index"
import Title from "@/modules/users/pages/phantoms/components/title/Title"
import Modal from '@/modules/users/pages/phantoms/components/modals/create_update/Index'
import {useMeta} from "vue-meta"
import Helper from "@/services/Helper"
import IconCirclePlus from '@/modules/users/icons/IconCirclePlus'
import Permission from "../../services/Permission"
import NoPermission from "@/modules/auth/pages/no_permission/Index"
import {
  usersCode,
} from "@/modules/auth/services/permissions"

export default defineComponent({
  name: "Phantoms",
  components: {
    IconCirclePlus,
    PhantomFilter,
    PhantomList,
    Modal,
    Title,
    NoPermission,
  },
  setup() {
    useMeta({title: "Фантомы"})

    const scrollComponent = ref(null)

    let query = ref({})
    let clearResult = ref(false)
    let offset = ref(0)
    let eofResult = ref(false)
    let countPhantoms = ref(0)
    let isLoading = ref(false)
    let noPermission = ref(false)
    let scroll = () => {
      isLoading.value = true
      offset.value += 18

      onLoadMore(offset.value)
    }
    let params = {
      search: "",
      permissions: [],
      enable: true,
      statuses: {},
    }

    function onRender(payload) {
      eofResult.value = payload["oef_items"] === true
      if (payload["count_items"] > 0) {
        countPhantoms.value = payload["count_items"];
      }
      clearResult.value = false
      isLoading.value = false
    }

    function onFilter(q) {
      clearResult.value = true
      offset.value = 0
      eofResult.value = false
      countPhantoms.value = 0

      q = Helper.mergeObject(q, {
        "limit": 18,
        "offset": offset.value,
      })

      q = Helper.clone(q)

      query.value = q
    }

    function onLoadMore(offset) {
      if (Helper.isEmptyObject(query.value)) {
        return false
      }

      let q = Helper.clone(query.value)

      query.value = Helper.mergeObject(q, {
        "offset": offset,
      })
    }

    const handleScroll = () => {
      if (eofResult.value) {
        return
      }

      let element = scrollComponent.value

      if (!isLoading.value && element.getBoundingClientRect().bottom < window.innerHeight) {
        scroll()
      }
    }

    onMounted(() => {
      if (!checkPermission()) {
        return
      }

      window.addEventListener("scroll", handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll)
    })

    function checkPermission() {
      if (!Permission.hasPermissions(usersCode)) {
        setNoPermission()
        return false
      }

      return true
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function isForbiddenPage() {
      return noPermission.value
    }

    function getParams() {
      return params
    }

    function getQuery() {
      return query.value
    }

    function getClearResult() {
      return clearResult.value
    }

    function onUpdate() {
      //onFilter({})
    }

    function onCreate() {
      onFilter({
        statuses: {
          enabled: true,
        },
      })
    }

    function onDelete() {
      //onFilter({})
    }

    return {
      getParams,
      getQuery,
      getClearResult,
      onRender,
      onFilter,
      scrollComponent,
      onUpdate,
      onCreate,
      onDelete,
      countPhantoms,
      isForbiddenPage,
    }
  }
})

</script>

<style scoped src="./css/style.css"></style>
