<template>
  <div class="card" id="title">
    <div class="card-body">
      <div class="divide-y">
        <div class="row">
          <span class="col-auto">
            <label class="form-label">Заголовок</label>
          </span>
          <span class="col">
            <input type="text" class="form-control" v-model="fields.title" placeholder="Заголовок" @change="onChange"/>
            <div v-if="v$.title.$invalid">
              <span :class="{'is-invalid': v$.title.$invalid && v$.title.$dirty}"></span>
              <div class="invalid-feedback" v-for="error of v$.title.$silentErrors" :key="error.$uid">
                {{ error.$message }}
              </div>
            </div>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import {defineComponent, watch, onMounted, reactive} from "vue"
import {useVuelidate} from "@vuelidate/core/dist/index.esm"
import {minLength} from "@/plugins/vuelidator"

export default defineComponent({
  name: "Title",
  props: {
    data: {
      type: [Object, null],
      default: null,
    },
  },
  setup(props, {emit}) {
    const fields = reactive({
      title: null,
    })

    onMounted(() => {
      fields.title = props.data.val;
    })

    watch(() => props.data.val, (val) => {
      fields.title = val
    })

    const rules = {
      title: {
        minLength: minLength(0),
      },
    }

    let $externalResults = reactive({})

    const v$ = useVuelidate(rules, fields, {$externalResults})

    function validate(fn) {
      v$.value.$clearExternalResults()

      return v$.value.$validate().then(async () => {
        await fn()
      })
    }

    watch(() => props.data["validations"], (val) => {
      validate(() => {
        if (val?.title !== undefined) {
          $externalResults.title = val["title"][0]
        }
      })
    })

    function onChange() {
      validate(() => {
      })
      emit("onChange", fields.title)
    }

    return {
      onChange,
      v$,
      fields,
    }
  }
})
</script>

<style scoped>

</style>
