import PostApiBase from "@/modules/promopushka/services/ApiBase"

export default class Api {
    static deleteRecovery(postId) {
        return PostApiBase.deleteRecovery({
            id: postId,
        }).then((resp) => {
            return resp.data
        })
    }
}
