export default class Object {
    user_id = null
    user_name = ""

    constructor({user_id, user_name}) {
        this.user_id = user_id
        this.user_name = user_name
    }

    static buildData({user_id, user_name}) {
        return JSON.stringify({
            user_id,
            user_name,
        })
    }

    static getData(data) {
        return new Object(JSON.parse(data))
    }

    getUserId() {
        return this.user_id
    }

    getUserName() {
        return this.user_name
    }
}
