<template>
  <div>
    <metainfo></metainfo>
    <div id="coupon-card">
      <template v-if="conditions.isReadyPage()">
        <div class="container-xl">
          <div class="page-header d-print-none">
            <div class="row g-2 align-items-center">
              <div class="col-sm">
                <h2 class="page-title">
                  <TitlePage :substring="getTitle()" :count-coupons="0" />
                </h2>
              </div>
              <div class="col-auto" v-if="conditions.isShowUpdateBtn(13)">
                <div class="me-1">
                  <UpdateBtn :data="{
                    post_id: bootData.id,
                    post_channel_id: 13,
                  }" />
                </div>
              </div>
              <div class="col-auto" v-if="conditions.hasIdPost()">
                <div class="me-1">
                  <PostInChain :post-id="chainObject.getId()" :type-id="chainObject.getTypeId()" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-body">
          <div class="container-xl">
            <template v-if="conditions.hasIdPost()">
              <CommentModeration :payload="{
                categories: ['new'],
                limit: 20,
                title: 'Новые комментарии',
                filter: {
                  post_id: bootData.id,
                  object_type_id: commentModerationObject.getTypeId(),
                }
              }" />
            </template>
            <div class="row mb-3">
              <div class="col-md-8">
                <div class="card">
                  <div class="card-body">
                    <div class="mb-2">
                      <Title :data="{
                        title: bootData.title
                      }" />
                    </div>
                    <div class="mb-3">
                      <Editor :data="{
                        description: bootData.description
                      }"/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Инфо</h3>
                  </div>
                  <div class="card-body">
                    <div class="datagrid">
                      <div>
                        <Shop :data="{
                          shop_id: bootData.shop_id,
                          shops: shops
                        }" />
                      </div>
                      <div>
                        <TypeSteps :data="{
                          type_of_steps: bootData.type_of_steps
                        }" />
                      </div>
                      <div v-if="conditions.typeOfStepIsWithCheck()">
                        <UrlCheck :data="{
                          url_for_check: bootData.url_for_check
                        }" />
                      </div>
                      <div v-if="conditions.typeOfStepIsWithCheck()">
                        <CodeCheck :data="{
                          code_to_check: bootData.code_to_check
                        }" />
                      </div>
                      <div>
                        <TypeCoupon :data="{
                          type_of_coupon: bootData.type_of_coupon,
                          type_of_steps: bootData.type_of_steps
                        }"/>
                      </div>
                      <div v-if="conditions.typeOfCouponIsOneToMany()">
                        <Coupon :data="{
                          coupon: bootData.coupon
                        }" />
                      </div>
                      <div>
                        <UrlActivate :data="{
                          url_to_activate: bootData.url_to_activate
                        }" />
                      </div>
                      <div>
                        <Status :data="{
                          status: bootData.status
                        }" />
                      </div>
                      <div>
                        <IsPartnerLinks :data="{
                          partner_links: bootData.partner_links,
                        }" />
                      </div>
                      <div v-if="!conditions.isDeleted()">
                        <AutoDeleteDate :data="{
                          auto_delete_date: bootData.auto_delete_date,
                        }" />
                      </div>
                      <div v-if="!conditions.isDeleted()">
                        <UploadDate :data="{
                          upload_date: bootData.upload_date,
                        }" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="conditions.needCouponList()">
              <CouponList :coupon="bootData.id" />
            </div>
            <div class="mb-3" v-if="conditions.hasIdPost()">
              <PostRefPosts :data="{
                  id: chainObject.getId(),
                  channel_id: chainObject.getChannelId(),
                  status: chainObject.getStatus(),
                  del: chainObject.getDel(),
                }" :type-id="chainObject.getTypeId()" />
            </div>
            <div class="mb-3" v-if="conditions.hasIdPost()">
              <CommentModeration :payload="{
                  post_id: bootData.id,
                  categories: ['approved', 'rejected', 'removed'],
                  object_type_id: commentModerationObject.getTypeId(),
                  limit: 20,
                  force_show: true,
                  title: 'Промодерированные комментарии',
                  filter: {
                    post_id: bootData.id,
                    object_type_id: commentModerationObject.getTypeId(),
                  }
                }" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="page-body">
          <div class="container-xl">
            <div class="row">
              <div class="mb-3">
                <div class="loader-posts">
                  <div class="spinner-border"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import './style.css'
import {defineComponent, onMounted, ref, reactive, watch} from "vue"
import {useMeta} from "vue-meta"
import {useRoute} from "vue-router/dist/vue-router"
import Editor from "./components/editor/Editor"
import Title from "./components/title/Title"
import TypeSteps from "./components/TypeSteps"
import TypeCoupon from "./components/TypeCoupon"
import Status from "./components/Status"
import Shop from "./components/Shop"
import UrlCheck from "./components/UrlCheck"
import CodeCheck from "./components/CodeCheck"
import UrlActivate from "./components/UrlActivate"
import Coupon from "./components/Coupon"
import IsPartnerLinks from "./components/IsPartnerLinks"
import AutoDeleteDate from "./components/AutoDeleteDate"
import UploadDate from "./components/UploadDate"
import TitlePage from "@/modules/promopushka/components/title/Title"
import Api from "./services/Api"
import CouponList from './components/CouponList'
import PostRefPosts from "@/components/object_chains/PostRefPosts"
import cfg from "@/services/Config"
import PostStoreCommon from "@/modules/post/pages/posts/services/PostStoreCommon"
import PostInChain from "@/components/post_in_chain/PostInChain"
import CommentModeration from "@/components/comment_moderation/CommentModeration"
import Notify from "@/services/Notify"
import UpdateBtn from "../../components/update_btn/UpdateBtn"
import PostPermission from "@/modules/post/services/Permission"

export default defineComponent({
  name: "Index",
  components: {
    UpdateBtn,
    CommentModeration,
    TitlePage,
    Editor,
    TypeSteps,
    TypeCoupon,
    Title,
    Status,
    Shop,
    UrlCheck,
    CodeCheck,
    UrlActivate,
    Coupon,
    IsPartnerLinks,
    AutoDeleteDate,
    UploadDate,
    CouponList,
    PostRefPosts,
    PostInChain,
  },
  setup() {
    const route = useRoute()

    let readyPage = ref(false)
    let couponId = ref(null)
    let validations = ref({})
    let shops = ref([])
    let bootData = reactive({
      id: null,
      shop_id: null,
      title: null,
      description: null,
      type_of_steps: 0,
      type_of_coupon: 0,
      coupon: null,
      url_to_activate: null,
      url_for_check: null,
      code_to_check: null,
      status: 0,
      partner_links: 1,
      auto_delete_date: null,
      upload_date: null,
      send_to_tg: 0,
      del: null,
    })
    let chainObject = {
      getId: () => {
        return bootData.id
      },
      getChannelId: () => {
        return 13
      },
      getStatus: () => {
        if (bootData.status === 10) {
          return "published"
        } else if (bootData.status === 0) {
          return "draft"
        } else {
          throw Error("Не определен статус")
        }
      },
      getDel: () => {
        return bootData.del
      },
      getTypeId: () => {
        return cfg._("object_chains", "types", "promopushka")
      }
    }
    let commentModerationObject = {
      getTypeId: () => {
        return cfg._("comment_moderation", "types", "promopushka")
      }
    }
    let conditions = {
      typeOfStepIsWithCheck: () => {
        return bootData.type_of_steps === 20
      },
      typeOfCouponIsOneToMany: () => {
        return bootData.type_of_coupon === 0
      },
      isReadyPage: () => {
        return readyPage.value === true
      },
      needCouponList: () => {
        return bootData.id !== null && bootData.type_of_coupon === 10
      },
      isDeleted: () => {
        return bootData.del === 1
      },
      hasIdPost: () => {
        return bootData.id !== null
      },
      isShowUpdateBtn(channelId) {
        return PostPermission.isUpdate(channelId)
      },
    }

    function initBootData(data) {
      Object.assign(bootData, data)
    }

    watch(bootData, () => {
      PostStoreCommon.handle({
        "channel_id": 13,
        "route_name": "PromopushkaCreateUpdate",
      })
    })

    onMounted(async () => {
      useMeta({title: getTitle()})

      readyPageOff()

      if (route?.params?.id) {
        couponId.value = parseInt(route.params.id)
      }

      Api.shop().then(async (resp) => {
        shops.value = resp.items
      })

      Api.item(couponId.value).then(async (resp) => {
        initBootData(resp.item)
        readyPageOn()
      }).catch((err) => {
        Notify.error('Скидка не найдена', err)
      })
    })

    function readyPageOn() {
      readyPage.value = true
    }

    function readyPageOff() {
      readyPage.value = false
    }

    function getTitle() {
      return "Просмотр скидки"
    }

    return {
      getTitle,
      conditions,
      validations,
      bootData,
      shops,
      chainObject,
      commentModerationObject,
    }
  }
})
</script>

<style scoped></style>
