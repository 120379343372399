<template>
  <router-link class="dropdown-item" :to="{ name: 'Shops' }" v-if="isView()">
    <span class="nav-link-icon d-md-none d-lg-inline-block">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-shopping-bag"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M6.331 8h11.339a2 2 0 0 1 1.977 2.304l-1.255 8.152a3 3 0 0 1 -2.966 2.544h-6.852a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304z" /><path d="M9 11v-5a3 3 0 0 1 6 0v5" /></svg>
    </span>
    <span class="nav-link-title">
      Магазины
    </span>
  </router-link>
</template>

<script>
import Permission from "@/modules/catalog/services/Permission"
import {onMounted, ref} from "vue"

export default {
  name: "Shops",
  setup() {
    let noPermission = ref(false)

    onMounted(() => {
      checkPermission()
    })

    function checkPermission() {
      if (!Permission.hasRoles("editor") && !Permission.hasRoles("admin")) {
        setNoPermission()
      }
    }

    function setNoPermission() {
      noPermission.value = true
    }

    function isView() {
      return !noPermission.value
    }

    return {
      isView,
    }
  },
}
</script>

<style scoped>

</style>